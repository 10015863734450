import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

//Icons
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  HomeRounded,
} from "@material-ui/icons";

//Translation
import useTranslation from "next-translate/useTranslation";

const PromotionCard = ({ promotion }) => {
  const { lang } = useTranslation();
  const isEnglish = lang === "en";

  return (
    <Grid item md={5}>
      <Box clone marginY={3}>
        {promotion.icon}
      </Box>
      <Box minHeight="5vh">
        <Box clone paddingX={4}>
          <Typography variant="h3">{promotion.name}</Typography>
        </Box>
      </Box>
      <Box minWidth={300} minHeight="25vh" paddingY={4} paddingX={4}>
        <List>
          <ListItem>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align={isEnglish ? "left" : "right"}
            >
              {promotion.promotion1}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align={isEnglish ? "left" : "right"}
            >
              {promotion.promotion2}
            </Typography>
          </ListItem>
          {promotion.promotion3 ? (
            <ListItem>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align={isEnglish ? "left" : "right"}
              >
                {promotion.promotion3}
              </Typography>
            </ListItem>
          ) : null}
          {promotion.promotion4 ? (
            <ListItem>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align={isEnglish ? "left" : "right"}
              >
                {promotion.promotion4}
              </Typography>
            </ListItem>
          ) : null}
        </List>
        <Typography variant="subtitle2" color="textSecondary"></Typography>
      </Box>
      {/* <Box paddingX={4} display="flex" flexDirection="row" alignItems="center">
        {isEnglish ? <ArrowForwardIosOutlined /> : <ArrowBackIosOutlined />}
        <Typography variant="subtitle1">{promotion.linkText}</Typography>
      </Box> */}
    </Grid>
  );
};

export default PromotionCard;
