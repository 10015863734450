import { Box, Grid, Typography } from "@material-ui/core";

//Components
import { Section } from "../../common";
import PromotionCard from "./PromotionCard";

//Icons
import {
  BorderColor,
  FolderSharedTwoTone,
  VerifiedUserTwoTone,
  LocalFloristTwoTone,
} from "@material-ui/icons";

//Translation
import useTranslation from "next-translate/useTranslation";

const Promotions = () => {
  const { t } = useTranslation();

  const promotions = [
    {
      name: t("home:officialLicensesOfTheCompany"),
      promotion1: t("home:companysLicenseAndItsValidity"),
      promotion2: t("home:signatoryAuthorizationLetterAndItsValidity"),
      promotion3: t("home:otherLicensesThatAreRelatedToCompany"),
      promotion4: t("home:workingPermitAndItsValidity"),
      linkText: t("home:learnMore"),
      icon: <VerifiedUserTwoTone color="primary" fontSize="large" />,
    },
    {
      name: t("home:ministryOfSocialAffairsDocument"),
      promotion1: t("home:numberOfRegisteredWorkers"),
      promotion2: t("home:workPermitAndItsValidity"),
      promotion3: t("home:otherLicensesRelatedToEmployees"),
      linkText: t("home:learnMore"),
      icon: <FolderSharedTwoTone color="primary" fontSize="large" />,
    },
    {
      name: t("home:employementContract"),
      promotion1: t("home:employeesAccountingAndLegalBenefits"),
      promotion2: t("home:regulatingRelationshipBetweenEmployerAndEmployee"),
      linkText: t("home:learnMore"),
      icon: <BorderColor color="primary" fontSize="large" />,
    },
    {
      name: t("home:pifss"),
      promotion1: t("home:owedAmountsAndPaymentHistory"),
      promotion2: t("home:numberOfEmployeesRegistered"),
      linkText: t("home:learnMore"),
      icon: <LocalFloristTwoTone color="primary" fontSize="large" />,
    },
  ];

  const promotionsList = promotions.map((promotion, i) => (
    <PromotionCard promotion={promotion} key={(promotion, +i)} />
  ));

  return (
    <Section>
      <Typography align="center" variant="subtitle1" color="textSecondary">
        {t("home:promotionsTitle")}
      </Typography>
      <Box clone minHeight={"400px"} paddingY={15}>
        <Grid container spacing={6} alignItems="center" justify="center">
          {promotionsList}
        </Grid>
      </Box>
    </Section>
  );
};

export default Promotions;
