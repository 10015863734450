import { Box, Grid, Typography } from "@material-ui/core";

//Components
import { FullWidthImage, Link, Section, StyledButton } from "../common";

//Translation
import useTranslation from "next-translate/useTranslation";

const Header = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Box clone minHeight={"400px"} paddingY={8}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item md={6}>
            <Typography variant="h1" align="center">
              {t("home:headerTitle")}
              <strong>{t("home:headerStrong")}</strong>
            </Typography>
            <Box clone paddingY={3}>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
              >
                {t("home:headerSubtitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box component={Link} href={"/comingSoon"}>
              <StyledButton
                paddingX={4}
                variant="contained"
                color="primary"
                text={t("home:start")}
              />
            </Box>
          </Grid>
          <Box clone paddingY={4}>
            <Grid container alignItems="center" justify="flex-start">
              <Grid item md={12}>
                <Typography
                  align="center"
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {t("home:focusOnYourBusiness")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid item md={12}>
            <FullWidthImage src="/images/HeaderImageBlurred.png" />
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};

export default Header;
